import { HydrateOption } from '@core/enums';
import { isSSR } from '@helpers/ssr';
import withRedux from '@helpers/withRedux';
import withWidget from '@hoc/withWidget';
import Loader from '@stories/Components/Misc/Loader/Loader';
import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
const LazyLoginModal = React.lazy(() => import('./LoginModal.lazy'));
const LoginModal = (props) => {
    const loginModalType = useSelector((x) => x.app.loginModalType);
    if (isSSR() || loginModalType === undefined) {
        return null;
    }
    return (React.createElement(Suspense, { fallback: React.createElement(Loader, { delay: 500, container: true }) },
        React.createElement(LazyLoginModal, { ...props })));
};
export default withWidget(withRedux(LoginModal), 'LoginModal', { hydrate: HydrateOption.InView });

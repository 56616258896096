import React, { useEffect, useState } from 'react';
import { LoaderStyles } from './Loader.styles';
const Loader = (props) => {
    const [showLoader, setShowLoader] = useState(props.delay === undefined);
    useEffect(() => {
        if (!props.delay) {
            return;
        }
        const handle = setTimeout(() => setShowLoader(true), props.delay);
        return () => window.clearTimeout(handle);
    }, []);
    if (!showLoader) {
        return null;
    }
    const Loader = (React.createElement(LoaderStyles.Wrapper, null,
        React.createElement(LoaderStyles.Loader, null)));
    if (props.container === true) {
        return (React.createElement(LoaderStyles.Container, null,
            React.createElement(LoaderStyles.ContainerInner, null, Loader)));
    }
    return Loader;
};
export default Loader;

import brand from '@helpers/brand';
import { from, Device } from '@helpers/media';
import styled, { keyframes } from 'styled-components';
const width = 10;
const size = 70;
const loadAnimation = keyframes `
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;
const Loader = styled.div `
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: ${width}px solid rgba(0, 0, 0, 0.1);
  border-right: ${width}px solid rgba(0, 0, 0, 0.1);
  border-bottom: ${width}px solid rgba(0, 0, 0, 0.1);
  border-left: ${width}px solid ${brand.grey['grey20']};
  transform: translateZ(0);
  animation: ${loadAnimation} 1.1s infinite linear;
  border-radius: 50%;
  width: ${size}px;
  height: ${size}px;

  &:after {
    content: '';
    border-radius: 50%;
    width: ${size}px;
    height: ${size}px;
  }
`;
const Wrapper = styled.div ``;
const Container = styled.section `
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  height: 100vh;
  justify-content: center;
  text-align: center;
  width: 100%;
  /* Higher index than header container */
  z-index: 8002;
  display: flex;
`;
const ContainerInner = styled.div `
  position: relative;
  display: block;
  background: #fff;
  border: 1px solid #b9b9b9;
  max-height: 100%;
  max-width: 665px;
  overflow-y: auto;
  padding: 24px 16px 24px;
  width: 100%;

  @media ${from(Device.Tablet)} {
    padding: 32px 108px 48px;
  }
`;
export const LoaderStyles = {
    Wrapper,
    Loader,
    Container,
    ContainerInner,
};
